@tailwind utilities;
@tailwind components;
@tailwind base;

@layer components {
  .form-wrapper {
    @apply w-full lg:w-1/3 mx-auto border-2 border-black p-2 lg:p-5 rounded-md;
  }
  .form-wrapper .form-group {
    @apply flex flex-col gap-1 w-full text-left;
  }
  .form-wrapper .form-group input,
  .form-wrapper .form-group textarea,
  .form-wrapper .form-group select {
    @apply p-2 px-4 rounded-md outline-none border-2 w-full text-black;
  }

  .form-wrapper form {
    @apply flex flex-col gap-3;
  }
}
