@tailwind utilities;
@tailwind components; 
@tailwind base;

@layer components{
    table td, table th {
        @apply p-4; 
    }
    
   
}
