.text-gold {
  color: gold;
}

.text-gray {
  color: gray;
}

.star {
  font-weight: bold;
}
