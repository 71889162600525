@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;800&display=swap");

::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background: rgb(16, 203, 228);
}
::-webkit-scrollbar-track {
  background: grey;
  padding: 1px;
}

.services ::-webkit-scrollbar,
.providers ::-webkit-scrollbar {
  height: 0px;
}

@layer components {
  .input-icon-custom {
    @apply absolute left-5 top-4 text-primary;
  }
  .main-x-p {
    @apply lg:px-[10%] px-2;
  }
  .main-y-p {
    @apply py-10 lg:py-20;
  }
  .transition-slow {
    @apply transition-all duration-300;
  }

  .btn-primary {
    @apply rounded-full p-2 px-3 bg-slate-100 text-black text-lg;
  }
  .btn-dark {
    @apply rounded-full p-2 px-3 bg-black text-white text-center;
  }
  .btn-hover {
    @apply hover:bg-black hover:text-primary duration-200 transition-all;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  --color-primary: #10cbe4;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nanum Myeongjo", "Trebuchet",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

/* // form  */
.form-wrapper {
  @apply w-full lg:w-1/3;
}
.form-group {
  @apply flex flex-col gap-1 w-full text-left;
}
.form-group input,
.form-group textarea,
.form-group select {
  @apply p-2 px-4 rounded-md outline-none border-2 w-full text-black;
}
.form-wrapper form {
  @apply flex flex-col gap-3;
}

.form-wrapper .btn-primary {
  @apply bg-primary;
}
