
.error-wrapper{
    margin: 10% auto;
    color: black;
}

.error-wrapper .nav-link{
    text-decoration: none;
    color: inherit;
    vertical-align: baseline;
    background-color: #10CBE4;
    padding: 15px 30px;
}
